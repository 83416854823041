import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import * as React from 'react';

import { Box, Button, Typography, useTheme } from '..';

export interface Props {
  dataQa?: string;
  displayArrow?: boolean;
  fullWidth?: boolean;
  label?: string;
  leftButtonDisabled?: boolean;
  onLeftClicked?: () => void;
  onRightClicked?: () => void;
  rightButtonDisabled?: boolean;
}

export const SliderSelector: React.FC<Props> = ({
  dataQa = 'slider-selector',
  leftButtonDisabled,
  rightButtonDisabled,
  label,
  onLeftClicked,
  onRightClicked,
  displayArrow = true,
  fullWidth,
}) => {
  const {
    sfSliderSelector: { styles: sfSliderSelectorStyles },
  } = useTheme();

  const border = `${sfSliderSelectorStyles.border}`;
  const backgroundColor = `${sfSliderSelectorStyles.backgroundColor}`;
  const buttonSx = {
    backgroundColor,
    border,
    color: 'primary.main',
    minWidth: 'auto',
    '&.MuiButton-contained, &:hover': { boxShadow: sfSliderSelectorStyles.boxShadow },
    '&:hover': { backgroundColor: 'action.hover' },
    '& *': {
      pointerEvents: 'none',
    },
  };

  const arrowIconStyle = displayArrow
    ? {
        '&:after': {
          content: '""',
          position: 'absolute',
          width: 0,
          height: 0,
          ml: '-11px',
          bottom: -15,
          left: '50%',
          border: '8px solid black',
          borderColor: '#fff',
          boxSizing: 'border-box',
          boxShadow: `${sfSliderSelectorStyles.boxShadowCaret}`,
          transformOrigin: '0 0',
          transform: 'rotate(-45deg)',
          zIndex: '-1',
        },
      }
    : {};

  return (
    <Box
      data-qa={dataQa}
      sx={{
        display: 'flex',
        position: 'relative',
        width: fullWidth ? '100%' : 'fit-content',
      }}
    >
      <Button
        className="slider-clickable-element"
        data-qa={`${dataQa}-cta-left`}
        disabled={leftButtonDisabled}
        onClick={onLeftClicked}
        startIcon={<ArrowBackIosIcon fontSize="small" sx={{ ml: 1 }} />}
        sx={{
          ...buttonSx,
          borderRadius: '50px 0 0 50px',
          pr: 0,
        }}
        variant="contained"
      />
      <Typography
        align="center"
        className="slider-clickable-element"
        data-qa={`${dataQa}-label`}
        sx={{
          alignItems: 'center',
          backgroundColor,
          borderBottom: border,
          borderTop: border,
          color: 'text.primary',
          display: 'flex',
          justifyContent: 'center',
          px: 1,
          height: sfSliderSelectorStyles.height,
          width: fullWidth ? '100%' : sfSliderSelectorStyles.width,
          zIndex: 1,
          '&, &:hover': {
            boxShadow: sfSliderSelectorStyles.boxShadow,
          },
          ...arrowIconStyle,
        }}
        variant="caption"
      >
        {label}
      </Typography>
      <Button
        className="slider-clickable-element"
        data-qa={`${dataQa}-cta-right`}
        disabled={rightButtonDisabled}
        endIcon={<ArrowForwardIosIcon className="slider-clickable-element" fontSize="small" />}
        onClick={onRightClicked}
        sx={{
          ...buttonSx,
          borderRadius: '0 50px 50px 0',
          pl: 0.5,
        }}
        variant="contained"
      />
    </Box>
  );
};
