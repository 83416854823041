import React, { useContext } from 'react';

import { getSymphonyUrl } from '../routes';

import { BrokerageAccountLinkingMethods, FADashboardTabs, FundingSources } from './types';

import {
  ClientReviewStatus,
  DocumentCategoryEnum,
  FinancialAccountType,
  PerformanceMethodTypes,
  TransferFrequency,
} from '~/__generated__';
import { DropdownVariant } from '~/components';
import { DateRangeOptions } from '~/components/ControlFields/DateFilters/types';
import { SupportedFileTypes } from '~/components/modals/ConnectBrokerageAccount/types';
import { AllowedAddFundsSourcesType } from '~/containers/AccountSummary/types';
import { MergedClientReviewStatus } from '~/containers/FADashboard/utils';
import { AmountValidationType } from '~/containers/Funding/utils';
import { defaultHomeOfficeDashboardConfig, HomeOfficeDashboardConfig } from '~/containers/HomeOfficeDashboard/config';
import { HiddenOption } from '~/containers/Paperwork/Section/Questions/types';
import { AccountDetailsSectionBlock } from '~/hooks';
import { AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { PlaidLinkType } from '~/hooks/plaid-link/exports';
import { AllocationDetailsColumns, AssetAllocationTableColumns } from '~/utils/config/types';

export interface DefaultFundingSource {
  amountPrefillPercentage?: number;
  defaultSource: FundingSources;
  sourceIndex: number;
}
export interface RecurringDepositPrefills {
  amountPrefillPercentage?: number;
  annualSalaryDataPoint?: string;
  dayOfMonth?: number;
  dayOfWeek?: number;
  frequency?: TransferFrequency;
}

export interface TaxDay {
  day?: number;
  month?: number;
}
export interface CoreConfig {
  components: {
    sfAccountActivity: {
      dateOptions: DateRangeOptions[];
    };
    sfAccountDetails: {
      enableViewPerformanceReport: boolean;
      minorAgeLimit: number;
      showAccountBalanceChart?: boolean;
      showActualAllocationInDonut: boolean;
      showClosedAccountsInDropdown: boolean;
      showFactSheetLinkInAssetAllocation: boolean;
      showPortfolioNameInAssetAllocation: boolean;
    };
    sfAccountDetailsOverview: {
      performanceChartTo: 'today' | 'syncedOn';
      performanceDropdownVariant: DropdownVariant;
      performanceMethod: PerformanceMethodTypes;
      securityIds: string[];
      showAccountBalanceChart: boolean;
      showActualAllocationInDonut: boolean;
      showContactAdvisorCta: boolean;
      showFactSheetLinkInAssetAllocation: boolean;
      showPerformanceCalculationInfo: boolean;
      showPortfolioNameInAssetAllocation: boolean;
      showReturnsData: boolean;
    };
    sfAccountDetailsV2: {
      defaultContributionAmount: string;
      hiddenSections: AccountDetailsSectionBlock[];
      ignoreInsufficientFunds: boolean;
      plaidLinkageType: PlaidLinkType;
      showNameAsText: boolean;
      showPhoneNumber: boolean;
      showVerifiedBankAccounts: boolean;
      skipAccountFundsInformation: boolean;
      skipAccountMinimums: boolean;
      syncExternalBankAccounts: boolean;
      tabs: AccountDetailsTabsEnum[];
    };
    sfAccountPerformance: {
      partnerMarketReviewUrl?: string;
      securityIds: string[];
      showActualAllocationInDonut: boolean;
      showAdditionalClientsInfoForAccountType: FinancialAccountType[];
      showFactSheetLinkInAssetAllocation: boolean;
      showFullAccountNumberInPdf: boolean;
      showOnlyYearsInChart?: boolean;
      showPartnerMarketReviewUrl: boolean;
      showPortfolioNameInAssetAllocation: boolean;
    };
    sfAccountProfile: {
      showSecondaryCTA: boolean;
    };
    sfAccountSummary: {
      activeAccountsOnly: boolean;
      fallBackToStagedModelPortfolio: boolean;
      minorAgeLimit: number;
      showBillingRestrictionAlert: boolean;
      showContactAdvisorCta: boolean;
      showPendingStandingInstructionCard: boolean;
    };
    sfActiveSuspensions: {
      addPartnerOpsSuspensionAllowed: boolean;
      removePartnerOpsSuspensionAllowed: boolean;
    };
    sfAddFunds: {
      disableDateAfterXDays: number | null;
      disableDateCurrentYearForRetirementAccounts: boolean;
      disableDateMarketHolidays: boolean;
      disableDateWeekends: boolean;
    };
    sfAssetConsolidation: {
      assetConsolidatedAccountAttributeName?: string;
      brokerageAccountLinkingMethods?: BrokerageAccountLinkingMethods[];
      maxUploadFileSize: number;
      supportedUploadFileType: SupportedFileTypes[];
    };
    sfClientDocuments: {
      downloadDocumentBaseUrl: string;
      tabs: DocumentCategoryEnum[];
    };
    sfCloseAccount: {
      showEndEngagementTaxWithholdingSteps: boolean;
      showShouldSellAssets: boolean;
    };
    sfComparison: {
      showInvestmentObjectiveQuestion: boolean;
      showRecommendationRationaleQuestion: boolean;
    };
    sfDownloadProposal: {
      allocationDetailsColumns: AllocationDetailsColumns[];
      displayAllocationBar: boolean;
      showModelPortfolioDescription: boolean;
    };
    sfDownloadQPR: {
      allocationDetailsColumns: AllocationDetailsColumns[] | AssetAllocationTableColumns[];
      disclosureAtBottom: boolean;
      displayAllocationBar: boolean;
      formatPhoneNumbers: boolean;
      showActualAllocationInDonut: boolean;
    };
    sfFADashboard: {
      tabs: FADashboardTabs[];
    };
    sfFaPlaidFlow: {
      loginBaseUrl: string;
      otpExpiryTimeInMinutes: number;
      showProductImage: boolean;
    };
    sfFunding: {
      ageDataPoint: string;
      allowBothFundLaterAndBankSourceTogether: boolean;
      allowFundingToSetFundingValue: boolean;
      allowManualBrokerageEntry: boolean;
      amountValidationBasedOnAccountTypes: AmountValidationType[];
      brokerageAccountNumberValidationRegex: RegExp;
      contributionYearTaxDay?: TaxDay;
      custodianMaximumDailyLimit?: number;
      custodianMinimumDailyLimit?: number;
      defaultFundingSources?: DefaultFundingSource[];
      enabledAccountTypes: FinancialAccountType[]; // only used in FundingV2
      enabledFundingSources: FundingSources[]; // only used in FundingV2
      filterAccountTypesBasedOnMadlibsFieldDataPoint?: string;
      filterAccountTypesBasedOnSelectedPortfolio?: boolean;
      initialFundingAmountUpperLimitPercentage: number | null; // null = no upper limit
      initialFundingAmoutDataPoint?: string;
      investmentAmountPrefillForAnnuity?: number;
      isFundLaterReasonEnabled?: boolean;
      onlyEnableLikeToLikeJournaling?: boolean;
      recurringDepositPrefills?: RecurringDepositPrefills;
      retirementAccountDataPoint?: string;
      showCustodianCashField?: boolean;
      totalFundingSources: number;
      useManualBrokerageEntry?: boolean;
    };
    sfGoalDashboard: {
      GoalsCard?: {
        isProjectionGapCtaDisabled?: boolean;
      };
    };
    sfGoalDetails: { isSkipDisabled: boolean };
    sfGoalSelection: {
      allowSkipGoalCreationDuringOnboarding: boolean;
      isGoalDetailsModalEnabled: boolean;
    };
    sfHomeOffice: HomeOfficeDashboardConfig;
    sfInvestmentRestrictions: {
      maximumRestrictions: number | null;
    };
    sfLinkBankAccount: {
      accountNumberLength: number | null;
      allowedBanksWithRoutingNumbers: Record<string, string[]>;
      bankAccountNameLength: number | null;
      showBankNameField: boolean;
      showConfirmAccountNumberField: boolean;
      showConfirmRoutingNumberField: boolean;
      showNameOnAccountField: boolean;
    };
    sfMadlibs: {
      allowDisablingAgeField: boolean;
    };
    sfMarketing: {
      hideBackButtonFromMadlibs: boolean;
    };
    sfOnboarding: {
      useFundingV2: boolean;
    };
    sfOpsDashboard: {
      enableCommentDeletion: boolean;
      hiddenStatuses?: ClientReviewStatus[];
      mergedStatuses?: MergedClientReviewStatus[];
      showMoreInfoOnBankVerificationModal: boolean;
      showProductName: boolean;
    };
    sfPaperwork: {
      ageOfTerminationAttributeName: string;
      annualIncomeLimit?: number;
      assetsHeldAway?: {
        excludeQuestions?: string[];
      };
      autoFillCompanyNameFromTicker?: boolean;
      calculateNetWorth?: boolean;
      checkMaritalStatus?: boolean;
      defaultCountryCode: string;
      drivingLicenseIdentifier?: string;
      fetchRelatedContactsData: boolean;
      hiddenOptions?: HiddenOption[];
      isAffiliationLetterFieldEnabled?: boolean;
      isCustomRegulatorySection?: boolean;
      isPerStirpesEnabled: boolean;
      isReceivingEmailsControlledByEDeliveryEnrollment: boolean;
      isTrustTypeFieldsEnabled: boolean;
      onlyDriversIdentificationTypeForNonUsCitizen?: boolean;
      onlyPassportIdentificationTypeForResidentAliens?: boolean;
      passportIdentifier?: string;
      prefillAddressCountryCode: boolean;
      prefillIdentifierCountryForDrivingLicense: boolean;
      registeredStatesAttributeName?: string;
      registeredStatesSeparator?: string;
      statesWithUGMA: string[];
      trustedContact?: {
        allowSpecialCharacters?: boolean;
        eitherEmailOrPhoneRequired?: boolean;
        optionalCity?: boolean;
        optionalEmailAddress?: boolean;
        optionalPhoneNumber?: boolean;
        optionalState?: boolean;
        optionalStreetAddress?: boolean;
        optionalZipCode?: boolean;
      };
    };
    sfPlan: {
      detailedCheckList: {
        numberOfItemsInRow: number;
      };
      portfolioCompareAccountDetailsHoldings?: {
        disableExpenseRatio: boolean;
      };
      showPortfolioCompare: boolean;
    };
    sfPlayback: {
      showAllAssetTransfersAccumulated?: boolean;
      showEDisclosure: boolean;
      trustedContactSectionPrimary: 'beneficiary' | 'regulatory';
      trustedContactSectionSecondary: 'beneficiary' | 'regulatory';
    };
    sfPortfolioCompare: {
      showGradeIcons: boolean;
    };
    sfPortfolioDetails: {
      assetAllocationTableColumns: AssetAllocationTableColumns[];
    };
    sfPortfolioSelection: {
      assetAllocationTableColumns: AssetAllocationTableColumns[];
      enableEditInitialInvestment: boolean;
      filterPortfoliosByMadlibsTaxStatus: {
        dataPointKey: string;
        taxShelteredOptions: string[];
      } | null; // null = no filter
      hideFactSheet: boolean;
      showInvestmentAmount: boolean;
      showMinimumInvestmentAmountColumn: boolean;
      supportedTags: string[];
    };
    sfPricingSummary: {
      step?: number;
    };
    sfQuestionnaire: {
      allowDisablingAgeField: boolean;
    };
    sfSupportContact: {
      showContactAdvisorCta: boolean;
    };
    sfViewTransfers: {
      showPastTransfers: boolean;
    };
    sfWithdrawFunds: {
      disableDateAfterMaxWithdrawalDate: boolean;
      disableDateAfterXDays: number | null;
      disableDateCurrentYearForRetirementAccounts: boolean;
      disableDateMarketHolidays: boolean;
      disableDateTillAsSoonAsPossibleDate: boolean;
      disableDateWeekends: boolean;
      isDocusignRequiredForRetirementWithdrawals: boolean;
      showHardBlockForLowerLimit: boolean;
      soonestPossibleDays?: number;
    };
  };
  featureFlags: {
    addFundsSources: AllowedAddFundsSourcesType[];
    additionalRepCodeIdentifierNames?: {
      externalIdentifiers?: string[];
      partyAttributes?: string[];
    };
    allowDeletionForExternalBankAccounts: boolean;
    allowEditManuallyLinkedBankAccounts: boolean;
    allowPlaidReAuthentication: boolean;
    goals: {
      enableBranchIdForGoalCreation: boolean;
      enableGoalsFeature: boolean; // Toggles Goals Onboarding and RCE
    };
    isDocusignRequiredForFinancialAccountLinkageInRCE: boolean;
    isManualLinkageForBankAccountSupported: boolean;
    isManualLinkageForBrokerageAccountSupported: boolean;
    isPlaidLinkageForBankAccountSupported: boolean;
    isPlaidLinkageForBrokerageAccountSupported: boolean;
    isSuspendedAccountStateSupported?: boolean;
    msiEnabled: boolean;
    plaidLinkageType?: PlaidLinkType;
    repCodeIdentifierName?: string;
    rmdEnabled: boolean;
    showDeleteButtonOnAccountDetailPage: boolean;
    showErrorComponentOnInvalidPartyId?: boolean;
    showWarningForNonVerifiedFinancialAccountLinkages: boolean;
    useAddFundsV2: boolean;
  };
}

export const defaultCoreConfig: CoreConfig = {
  components: {
    sfAccountActivity: {
      dateOptions: [
        DateRangeOptions.Last30Days,
        DateRangeOptions.Last60Days,
        DateRangeOptions.Last90Days,
        DateRangeOptions.Custom,
      ],
    },
    sfAccountDetails: {
      enableViewPerformanceReport: false,
      minorAgeLimit: 18,
      showActualAllocationInDonut: false,
      showClosedAccountsInDropdown: false,
      showFactSheetLinkInAssetAllocation: false,
      showPortfolioNameInAssetAllocation: false,
    },
    sfAccountDetailsOverview: {
      performanceDropdownVariant: 'outlinedClassic',
      performanceMethod: PerformanceMethodTypes.FROM_END_OF_DAY_VALUES,
      showAccountBalanceChart: false,
      showPerformanceCalculationInfo: false,
      performanceChartTo: 'today',
      securityIds: ['BBG000BZZS63', 'BBG000TH7DF8'],
      showActualAllocationInDonut: false,
      showContactAdvisorCta: false,
      showFactSheetLinkInAssetAllocation: false,
      showPortfolioNameInAssetAllocation: false,
      showReturnsData: true,
    },
    sfAccountDetailsV2: {
      defaultContributionAmount: '100',
      hiddenSections: [],
      ignoreInsufficientFunds: false,
      plaidLinkageType: PlaidLinkType.FINANCIAL_ADVISOR,
      showNameAsText: true,
      showPhoneNumber: false,
      showVerifiedBankAccounts: true,
      skipAccountFundsInformation: false,
      skipAccountMinimums: false,
      syncExternalBankAccounts: false,
      tabs: [AccountDetailsTabsEnum.overview, AccountDetailsTabsEnum.documents, AccountDetailsTabsEnum.activity],
    },
    sfAccountPerformance: {
      partnerMarketReviewUrl: '',
      securityIds: ['BBG000BZZS63', 'BBG000TH7DF8'],
      showActualAllocationInDonut: false,
      showAdditionalClientsInfoForAccountType: [],
      showFactSheetLinkInAssetAllocation: false,
      showFullAccountNumberInPdf: false,
      showPartnerMarketReviewUrl: false,
      showPortfolioNameInAssetAllocation: false,
    },
    sfAccountProfile: {
      showSecondaryCTA: false,
    },
    sfAccountSummary: {
      activeAccountsOnly: true,
      fallBackToStagedModelPortfolio: false,
      minorAgeLimit: 18,
      showBillingRestrictionAlert: true,
      showContactAdvisorCta: false,
      showPendingStandingInstructionCard: true,
    },
    sfActiveSuspensions: {
      addPartnerOpsSuspensionAllowed: false,
      removePartnerOpsSuspensionAllowed: false,
    },
    sfAddFunds: {
      disableDateWeekends: true,
      disableDateAfterXDays: 365,
      disableDateMarketHolidays: true,
      disableDateCurrentYearForRetirementAccounts: false,
    },
    sfAssetConsolidation: {
      maxUploadFileSize: 3,
      supportedUploadFileType: [SupportedFileTypes.PDF],
    },
    sfClientDocuments: {
      downloadDocumentBaseUrl: getSymphonyUrl(),
      tabs: [
        DocumentCategoryEnum.STATEMENTS,
        DocumentCategoryEnum.TRADE_CONFIRMATIONS,
        DocumentCategoryEnum.TAX_REPORTS,
        DocumentCategoryEnum.OTHERS,
      ],
    },
    sfCloseAccount: {
      showEndEngagementTaxWithholdingSteps: true,
      showShouldSellAssets: true,
    },
    sfComparison: {
      showInvestmentObjectiveQuestion: false,
      showRecommendationRationaleQuestion: false,
    },
    sfDownloadProposal: {
      allocationDetailsColumns: [
        AllocationDetailsColumns.ASSET_CLASS_LABEL,
        AllocationDetailsColumns.SECURITY_NAME,
        AllocationDetailsColumns.ALLOCATION_PERCENTAGE,
      ],
      displayAllocationBar: false,
      showModelPortfolioDescription: false,
    },
    sfDownloadQPR: {
      allocationDetailsColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.TargetAllocation,
        AssetAllocationTableColumns.ActualAllocation,
        AssetAllocationTableColumns.Value,
      ],
      disclosureAtBottom: true,
      displayAllocationBar: false,
      formatPhoneNumbers: true,
      showActualAllocationInDonut: false,
    },
    sfFADashboard: {
      tabs: [
        FADashboardTabs.ActiveClientsTab,
        FADashboardTabs.ApplicationInProgressTab,
        FADashboardTabs.AccountActions,
      ],
    },
    sfHomeOffice: defaultHomeOfficeDashboardConfig,
    sfInvestmentRestrictions: {
      maximumRestrictions: null,
    },
    sfFaPlaidFlow: {
      loginBaseUrl: 'localhost:3000/plaid',
      otpExpiryTimeInMinutes: 1440,
      showProductImage: false,
    },
    sfFunding: {
      allowFundingToSetFundingValue: true,
      allowManualBrokerageEntry: true,
      allowBothFundLaterAndBankSourceTogether: false,
      ageDataPoint: 'data-point:age:integer',
      investmentAmountPrefillForAnnuity: 10,
      amountValidationBasedOnAccountTypes: [
        {
          accountType: FinancialAccountType.SEP_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 69000,
            valueLesserThanAge: 69000,
          },
          previousYear: {
            valueGreaterThanAge: 66000,
            valueLesserThanAge: 66000,
          },
        },
        {
          accountType: FinancialAccountType.TRADITIONAL_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
        {
          accountType: FinancialAccountType.ROTH_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
      ],
      brokerageAccountNumberValidationRegex: /[^a-z0-9-]/i,
      defaultFundingSources: [
        { defaultSource: FundingSources.bankAccount, amountPrefillPercentage: 30, sourceIndex: 0 },
        { defaultSource: FundingSources.otherBrokerageAccount, sourceIndex: 1 },
      ],
      enabledAccountTypes: [
        FinancialAccountType.INDIVIDUAL,
        FinancialAccountType.JOINT,
        FinancialAccountType.TRADITIONAL_IRA,
        FinancialAccountType.ROTH_IRA,
        FinancialAccountType.ROLLOVER_IRA,
        FinancialAccountType.SEP_IRA,
      ],
      enabledFundingSources: [
        FundingSources.bankAccount,
        FundingSources.otherBrokerageAccount,
        FundingSources.annuity,
        FundingSources.check,
        FundingSources.fundLater,
      ],
      onlyEnableLikeToLikeJournaling: true,
      useManualBrokerageEntry: false,
      initialFundingAmountUpperLimitPercentage: 10,
      initialFundingAmoutDataPoint: 'data-point:corev2-da-initial-funding:currency',
      isFundLaterReasonEnabled: false,
      recurringDepositPrefills: {
        amountPrefillPercentage: 10,
        annualSalaryDataPoint: 'data-point:corev2-da-annual-income:currency',
        dayOfMonth: 1,
        frequency: TransferFrequency.MONTHLY,
      },
      showCustodianCashField: false,
      totalFundingSources: 3,
    },
    sfGoalDashboard: {
      GoalsCard: {
        isProjectionGapCtaDisabled: false,
      },
    },
    sfGoalDetails: { isSkipDisabled: false },
    sfGoalSelection: {
      isGoalDetailsModalEnabled: true,
      allowSkipGoalCreationDuringOnboarding: true,
    },
    sfLinkBankAccount: {
      allowedBanksWithRoutingNumbers: {},
      showNameOnAccountField: true,
      accountNumberLength: 17,
      bankAccountNameLength: 25,
      showBankNameField: true,
      showConfirmRoutingNumberField: false,
      showConfirmAccountNumberField: false,
    },
    sfOnboarding: {
      useFundingV2: false,
    },
    sfPaperwork: {
      ageOfTerminationAttributeName: 'AGE_OF_TERMINATION',
      calculateNetWorth: false,
      checkMaritalStatus: false,
      defaultCountryCode: '',
      drivingLicenseIdentifier: 'DRIVING_LICENSE',
      fetchRelatedContactsData: false,
      prefillAddressCountryCode: false,
      prefillIdentifierCountryForDrivingLicense: false,
      passportIdentifier: 'PASSPORT',
      isCustomRegulatorySection: true,
      isPerStirpesEnabled: false,
      isReceivingEmailsControlledByEDeliveryEnrollment: true,
      isTrustTypeFieldsEnabled: false,
      isAffiliationLetterFieldEnabled: false,
      onlyDriversIdentificationTypeForNonUsCitizen: false,
      onlyPassportIdentificationTypeForResidentAliens: false,
      registeredStatesSeparator: ',',
      statesWithUGMA: ['GU', 'PR', 'VI'],
    },
    sfPlan: {
      detailedCheckList: {
        numberOfItemsInRow: 3,
      },
      showPortfolioCompare: true,
    },
    sfPortfolioCompare: {
      showGradeIcons: true,
    },
    sfPlayback: {
      showAllAssetTransfersAccumulated: true,
      trustedContactSectionPrimary: 'beneficiary',
      trustedContactSectionSecondary: 'beneficiary',
      showEDisclosure: true,
    },
    sfPortfolioDetails: {
      assetAllocationTableColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.Asset,
        AssetAllocationTableColumns.Allocation,
      ],
    },
    sfPortfolioSelection: {
      assetAllocationTableColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.Asset,
        AssetAllocationTableColumns.Allocation,
      ],
      enableEditInitialInvestment: true,
      filterPortfoliosByMadlibsTaxStatus: null,
      hideFactSheet: false,
      showInvestmentAmount: true,
      showMinimumInvestmentAmountColumn: true,
      supportedTags: [],
    },
    sfPricingSummary: {
      step: 0,
    },
    sfSupportContact: {
      showContactAdvisorCta: false,
    },
    sfMadlibs: {
      allowDisablingAgeField: false,
    },
    sfMarketing: {
      hideBackButtonFromMadlibs: false,
    },
    sfOpsDashboard: {
      enableCommentDeletion: true,
      showProductName: true,
      showMoreInfoOnBankVerificationModal: false,
    },
    sfQuestionnaire: {
      allowDisablingAgeField: false,
    },
    sfViewTransfers: {
      showPastTransfers: true,
    },
    sfWithdrawFunds: {
      disableDateWeekends: true,
      disableDateAfterXDays: 365,
      disableDateMarketHolidays: true,
      disableDateAfterMaxWithdrawalDate: true,
      disableDateCurrentYearForRetirementAccounts: false,
      disableDateTillAsSoonAsPossibleDate: true,
      isDocusignRequiredForRetirementWithdrawals: false,
      showHardBlockForLowerLimit: false,
    },
  },
  featureFlags: {
    addFundsSources: ['bankAccount'],
    allowDeletionForExternalBankAccounts: false,
    allowEditManuallyLinkedBankAccounts: false,
    allowPlaidReAuthentication: false,
    goals: {
      enableGoalsFeature: false,
      enableBranchIdForGoalCreation: false,
    },
    msiEnabled: false,
    isDocusignRequiredForFinancialAccountLinkageInRCE: false,
    isManualLinkageForBankAccountSupported: true,
    isManualLinkageForBrokerageAccountSupported: true,
    isPlaidLinkageForBankAccountSupported: false,
    isPlaidLinkageForBrokerageAccountSupported: false,
    isSuspendedAccountStateSupported: true,
    repCodeIdentifierName: '',
    rmdEnabled: false,
    showWarningForNonVerifiedFinancialAccountLinkages: false,
    showDeleteButtonOnAccountDetailPage: true,
    useAddFundsV2: false,
  },
};

const CoreConfigContext = React.createContext<CoreConfig>(defaultCoreConfig);

export const useCoreConfig = <T extends CoreConfig>(): T => {
  const coreConfig = useContext(CoreConfigContext);

  return coreConfig as T;
};

export const CoreConfigProvider = CoreConfigContext.Provider;

export * from './types';
