/**
 * PACKAGE ENTRY
 * export public methods and classes from this file
 *
 * For example:
 * export { default, NamedExport1, NamedExport2 } from './some-file';
 */
import fetch from 'cross-fetch';

import { API_KEY, CLIENT_NAME } from './src/constants';
import { ContentstackField, SysAssetConnection } from './src/types';

import { ApolloLink, HttpLink, HttpLinkConfig, MockLink, onError } from '~/utils/apollo-client';

export * from './src/hooks';
export * from './src/types';

export const getContentstackHttpLinkConfig = (environment: string, deliveryToken: string): HttpLinkConfig => ({
  name: CLIENT_NAME,
  link: new HttpLink({
    uri: `https://graphql.contentstack.com/stacks/${API_KEY}?environment=${environment}`,
    headers: {
      access_token: deliveryToken,
    },
    fetch,
  }),
});

export const getContentstackMockLinkConfig = (mockLink: MockLink): HttpLinkConfig => {
  const fallbackHttpLink = getContentstackHttpLinkConfig('dev', 'csaffbef3139b4b24aee2cc24c');
  return {
    name: CLIENT_NAME,
    link: ApolloLink.concat(
      onError(({ networkError, operation, forward }) => {
        if (networkError && networkError.message.includes('No more mocked responses')) {
          const liveResponse = fallbackHttpLink.link.request(operation, forward);
          if (liveResponse) {
            return liveResponse;
          }
        }
        return;
      }),
      mockLink,
    ),
  };
};

export const getImageAssetUrl = (assetConnection?: SysAssetConnection | null): string =>
  assetConnection?.edges?.[0]?.node?.url ?? '';

export const findFieldValue = (fields: (ContentstackField | null)[] | null | undefined, fieldKey: string): string =>
  fields?.find(field => field?.key === fieldKey)?.value ?? '';

type LabelPair = ({ key: string | null; label?: string | null; value?: string } | null)[] | null;

export const getValueFromKeyValuePair = (key: string, labelPair?: LabelPair | null): string => {
  const pair = labelPair?.find(item => item?.key === key);
  return pair?.label ?? pair?.value ?? '';
};
