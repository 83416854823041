import {
  GetRecommendedProductContent,
  GetRecommendedProductContent_all_recommended_product_items,
  GetRecommendedProductContent_all_recommended_product_items_attributes,
  GetRecommendedProductContent_all_recommended_product_items_attributes_RecommendedProductAttributesObjective,
  GetRecommendedProductContent_all_recommended_product_items_attributes_RecommendedProductAttributesRiskProfile,
  GetRecommendedProductContent_all_recommended_product_items_attributes_RecommendedProductAttributesTimeHorizon,
  GetRecommendedProductContent_all_recommended_product_items_bands,
  GetRecommendedProductContent_all_recommended_product_items_bands_band,
  GetRecommendedProductContent_all_recommended_product_items_diversification_block,
  GetRecommendedProductContentVariables,
} from './__generated__/GetRecommendedProductContent';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack/src/hooks';

export type DiversificationBlockContent = GetRecommendedProductContent_all_recommended_product_items_diversification_block;
export type RecommendedProductContent = GetRecommendedProductContent_all_recommended_product_items;
export type RecommendedProductContentAttribute = GetRecommendedProductContent_all_recommended_product_items_attributes;
export type RecommendedProductContentAttributeRiskProfile = GetRecommendedProductContent_all_recommended_product_items_attributes_RecommendedProductAttributesRiskProfile;
export type RecommendedProductContentAttributeObjective = GetRecommendedProductContent_all_recommended_product_items_attributes_RecommendedProductAttributesObjective;
export type RecommendedProductContentAttributeTimeHorizon = GetRecommendedProductContent_all_recommended_product_items_attributes_RecommendedProductAttributesTimeHorizon;

export type RecommendedProductContentBands = GetRecommendedProductContent_all_recommended_product_items_bands;
export type RecommendedProductContentBand = GetRecommendedProductContent_all_recommended_product_items_bands_band;

export const isAttributeObjective = (
  attribute: RecommendedProductContentAttribute | null,
): attribute is RecommendedProductContentAttributeObjective =>
  attribute?.__typename === 'RecommendedProductAttributesObjective';

export const isAttributeTimeHorizon = (
  attribute: RecommendedProductContentAttribute | null,
): attribute is RecommendedProductContentAttributeTimeHorizon =>
  attribute?.__typename === 'RecommendedProductAttributesTimeHorizon';

export const isAttributeRiskProfile = (
  attribute: RecommendedProductContentAttribute | null,
): attribute is RecommendedProductContentAttributeRiskProfile =>
  attribute?.__typename === 'RecommendedProductAttributesRiskProfile';

export const useGetRecommendedProductContent = (
  options?: QueryHookOptions<GetRecommendedProductContent, GetRecommendedProductContentVariables>,
) => {
  return useContentstackQuery(queries.GetRecommendedProductContent, options);
};
