import Decimal from 'decimal.js-light';
import { pipe } from 'fp-ts/lib/function';
import _startCase from 'lodash.startcase';

import { formatPercentage, FormatPercentageOptions } from '../number';

import { Locale } from '~/utils';

export const toPascalCase = (value: string): string => _startCase(value).replace(/ /g, '');

export const formatPercentageString = (
  value: string,
  { decimals = 2, removeTrailingZeroes = true, locale = Locale.en_us }: FormatPercentageOptions = {},
): string => {
  try {
    return pipe(
      value,
      v => new Decimal(v),
      v => v.dividedBy(100),
      v => formatPercentage(v, { decimals, removeTrailingZeroes, locale }),
    );
  } catch {
    return 'NaN';
  }
};

export const startCase = (str: string): string => _startCase(str.toLowerCase());

/**
 * Given a string with HTML tags, remove the HTML tags and return the text.
 * @param str - A string with HTML tags.
 * @returns The string without the HTML tags.
 */
export const stripHtmlTagsFromString = (str: string): string => {
  const tmp = document.createElement('div');
  tmp.innerHTML = str;

  return tmp.textContent || tmp.innerText;
};

export const formatPhoneNumberString = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '';
  }
  let countryCode;
  let phone = '';
  const phoneLength = phoneNumber.length;
  // We assume that all phoneNumbers are 10 numbers by default
  if (phoneLength > 10) {
    countryCode = phoneNumber.slice(0, phoneLength - 10);
    phone = phoneNumber.slice(phoneLength - 10, phoneLength);
  } else if (phoneLength === 10) {
    phone = phoneNumber;
  }
  return `${countryCode ? countryCode + '-' : ''}${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(
    6,
  )}`;
};
