import React from 'react';

import { CompactVariant, Indicator, PortfolioBand } from '.';
import { IndicatorComponent } from './IndicatorComponent';

import { Box } from '~/components/ui';

export interface Props {
  bandIndex: number;
  compact?: CompactVariant;
  dataQa?: string;
  indicator: Indicator;
  portfolioBands?: PortfolioBand[];
  riskBandHeight: number;
  riskBandHeightPrimary: number;
  riskBandNew: number;
}

export const PortfolioBands: React.FC<Props> = ({
  dataQa,
  bandIndex,
  compact,
  indicator,
  portfolioBands,
  riskBandHeight,
  riskBandHeightPrimary,
  riskBandNew,
}) => {
  const filteredPortfolioBands = portfolioBands?.filter(band => band.riskBandIndex === bandIndex);
  return (
    <Box
      data-qa={`${dataQa}-portfolio-band-wrapper-${bandIndex}`}
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: `${indicator?.isPrimary ? riskBandHeightPrimary : riskBandHeight}px`,
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        width: '100%',
      }}
    >
      {filteredPortfolioBands &&
        portfolioBands &&
        portfolioBands.map((pb, pbIndex) => {
          if (pb.riskBandIndex !== bandIndex) {
            return null;
          }
          return (
            <Box
              key={`${pb.label}-${pbIndex}`}
              sx={{
                alignItems: 'center',
                borderRight: '1px solid white',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
                width: `calc(100% / ${filteredPortfolioBands.length})`,
              }}
            >
              {pbIndex === riskBandNew && indicator?.label && (
                <IndicatorComponent
                  compact={compact}
                  dataQa={dataQa}
                  editMode
                  indicator={indicator}
                  riskBandHeightPrimary={riskBandHeightPrimary}
                  showPortfolioBands
                />
              )}
            </Box>
          );
        })}
    </Box>
  );
};
