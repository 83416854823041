export * from './account';
export * from './amplify';
export * from './analytics';
export * from './apollo-client';
export * from './asset-allocation/types';
export * from './brokerage';
export * from './client';
export * from './contentstack';
export * from './config';
export * from './cryptography';
export * from './date';
export * from './dropdown';
export * from './event-bus';
export * from './format';
export * from './functions';
export * from './math';
export * from './modern-theme';
export * from './navigation';
export * from './new-relic';
export * from './number-format';
export * from './pricing';
export * from './questionnaire';
export * from './rbac';
export * from './rbac/types';
export * from './responsiveness';
export * from './routes';
export * from './symphony';
export * from './storybook';
export * from './table';
export * from './test-utils';
export * from './theme';
export * from './interval';
export * from './validations';

export enum Environment {
  Bash = 'bash',
  Dev = 'dev',
  Local = 'local',
  Prod = 'prod',
  Staging = 'staging',
}

export interface AsyncResult<TData = any> {
  data?: TData;
  error?: Error;
  loading: boolean;
}

export interface LazyAsyncResult<TData = any> {
  load: () => void;
  result: AsyncResult<TData>;
}

/**
 * @deprecated by DA2-6027. Use AsyncResult instead.
 */
export type ComponentHookResult<TData = any> = AsyncResult<TData>;

/**
 * @deprecated by DA2-6027. Use LazyAsyncResult instead.
 */
export type LazyComponentHookResult<TData = any> = LazyAsyncResult<TData>;

export {
  allocationToDonutSlice,
  getAssetAllocations,
  isCompositeRecommendedPortfolio,
  isRecommendedPortfolio,
} from './asset-allocation';
