/**
 * Amplify HttpLinkConfigs
 */
import fetch from 'cross-fetch';

import { CLIENT_NAME } from './src/constants';

import { ApolloLink, HttpLink, HttpLinkConfig, MockLink, onError } from '~/utils/apollo-client';

export * from './src/hooks';

export const getGoalsHttpLinkConfig = (uri: string, accessToken: string): HttpLinkConfig => ({
  name: CLIENT_NAME,
  link: new HttpLink({
    uri,
    headers: {
      authorization: accessToken,
    },
    fetch,
  }),
});

export const getGoalsMockHttpLinkConfig = (
  mockLink: MockLink,
  onMissingMock?: (errorMsg: string) => void,
): HttpLinkConfig => ({
  name: CLIENT_NAME,
  link: ApolloLink.concat(
    onError(({ networkError }) => {
      if (networkError && networkError.message.includes('No more mocked responses')) {
        onMissingMock?.(networkError.message);
      }
    }),
    mockLink,
  ),
});
