import Decimal from 'decimal.js-light';
import { pipe } from 'fp-ts/lib/function';

import { Locale } from '~/utils';

export interface FormatPercentageOptions {
  convertRatioToPercentage?: boolean;
  decimals?: number | null;
  locale?: Locale;
  removeTrailingZeroes?: boolean;
}

export const formatPercentage = (
  value: number | Decimal,
  {
    convertRatioToPercentage = true,
    decimals = 2,
    removeTrailingZeroes = true,
    locale = Locale.en_us,
  }: FormatPercentageOptions = {},
): string =>
  pipe(
    value,
    v => new Decimal(v),
    v => (convertRatioToPercentage ? v.times(100) : v),
    v => (decimals !== null ? v.toFixed(decimals) : v.toString()),
    v => (removeTrailingZeroes ? parseFloat(v).toString() : v),
    v => (locale === Locale.fr_ca ? `${v.replace('.', ',')} %` : `${v}%`),
  );
