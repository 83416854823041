import { alpha, createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import merge from 'lodash.merge';
import { transparentize } from 'polished';
import { SVGProps } from 'react';

import { SfVariant } from './types';

import { SxProps, TypographyOptions } from '~/components/ui';

const palette = {
  // Material-ui will calculate the dark, light, etc from respective main values.
  // To allow partner themes utilize this automatic calculation, the core should not be setting them.
  primary: {
    main: '#2365F6',
    selected: alpha('#2365F6', 0.08),
  },
  secondary: {
    main: '#8C8C8C',
    dark: '#B23842',
  },
  text: {
    primary: '#434343',
    secondary: '#8c8c8c',
  },
  error: {
    main: '#F44336',
    light: '#feefee',
  },
  success: {
    main: '#4CAF50',
    dark: '#1d4620',
    light: '#ECF5F3',
  },
  other: {
    divider: '#E0E0E0',
    stroke: alpha('#000000', 0.23),
    backgroundBlue: '#363F50',
    backgroundGrey: '#FAFAFA',
    expandIcon: '#757575',
  },
  warning: {
    main: '#FF9800',
  },
  info: {
    main: '#2196F3',
    border: alpha('#1BB3BC', 0.5),
  },
};

const textAreaFocusBoxShadow = `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px ${transparentize(0.4, '#05bfff')}`;
const components: ThemeOptions['components'] = {
  /*
  Since the palette variables like 'text.primary' aren't resolving for the MuiComponent styleOverrides,
  get the palette through the theme: https://mui.com/material-ui/customization/theme-components/#overrides-based-on-props
  sfComponent styles do not have this issue however because they are passed into the sx props.
  It may not work for the svg properties like fill, stroke, etc., so you may still have to reference the palette object.
  In doing so, it also means that the same theme option need to be in the partner repos as well to reference the correct palette object values.
   */
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: '0px 0px',
        '&.Mui-expanded': {
          minHeight: '0px',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: '0px 0px',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '0px 16px 16px',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      label: {
        fontSize: '13px',
        lineHeight: '18px',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      size: 'large',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      contained: ({ theme }) => ({
        '@media (forced-colors: active)': {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      // If custom button size is not required in partner repo,
      // root style override can be cleared with empty object like root: () => ({})
      root: {
        '.MuiButton-root': {
          fontSize: 14,
          lineHeight: '24px',
          padding: '6px 16px',
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        color: '#000000',
        '&.Mui-focused': {
          boxShadow: textAreaFocusBoxShadow,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
        '&.Mui-focused': {
          color: 'inherit',
        },
      }),
    },
  },
  MuiInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-error': {
          color: theme.palette.error.main,
        },
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 12,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      button: {
        '&.MuiTypography-body2': {
          color: 'inherit',
          textDecorationColor: 'inherit',
          textDecoration: 'underline',
          fontWeight: 400,
        },
      },
    },
    defaultProps: {
      variant: 'body1',
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 40,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
        borderRadius: '4px',
        '&.Mui-focused': {
          boxShadow: textAreaFocusBoxShadow,
        },
      }),
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.MuiAccordion-root': {
          boxShadow: 'none',
        },
        '&:before': {
          backgroundColor: 'white',
        },
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: ({ theme }) => ({
        color: theme.palette.text.primary,
        '.MenuItem-label': {
          fontSize: 16,
          fontWeight: 400,
        },
      }),
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiStepConnector-completed, &.Mui-active': {
          '.MuiStepConnector-line': { borderColor: theme.palette.primary.main },
        },
      }),
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-disabled': {
          circle: {
            cx: 13,
            cy: 13,
            stroke: theme.palette.primary.main,
            transform: 'scale(0.9)',
          },
          // scale is to prevent edges of icon being cut off
          '.MuiStepIcon-root:not(.Mui-completed)': {
            color: 'transparent',
            transform: 'scale(1.1)',
          },
          '.MuiStepIcon-text': {
            fill: theme.palette.primary.main,
          },
        },
      }),
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        display: 'inline-block',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        textTransform: 'none',
      }),
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: { color: 'primary' },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        '&:first-of-type': {
          paddingLeft: 0,
        },
        '&:last-of-type': {
          paddingRight: 0,
        },
      },
    },
  },
  MuiTooltip: {
    defaultProps: { arrow: true },
    styleOverrides: {
      arrow: {
        color: '#FFFFFF',
      },
      tooltip: ({ theme }) => ({
        color: theme.palette.text.primary,
        backgroundColor: '#FFFFFF',
        borderRadius: '4',
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
        filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))',
        whiteSpace: 'pre-line',
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginLeft: 0,
        marginRight: 0,
        color: theme.palette.text.primary,
        '&.Mui-error': {
          color: 'error.main',
        },
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': { outline: '1px solid transparent' },
      },
    },
  },
};

const typography: TypographyOptions = {
  h1: {
    fontFamily: 'Core-Headline',
    fontSize: 36,
    fontWeight: 300,
  },
  h2: {
    fontFamily: 'Core-Headline',
    fontSize: 26,
    fontWeight: 300,
  },
  h3: {
    fontFamily: 'Core-Headline',
    fontSize: 22,
    fontWeight: 400,
  },
  h4: {
    fontFamily: 'Core-Text',
    fontSize: 22,
    fontWeight: 300,
  },
  h5: {
    fontFamily: 'Core-Text',
    fontSize: 20,
    fontWeight: 300,
  },
  h6: {
    fontFamily: 'Core-Text',
    fontSize: 18,
    fontWeight: 300,
  },
  subtitle1: {
    fontFamily: 'Core-Text',
    fontSize: 16,
    fontWeight: 600,
  },
  subtitle2: {
    fontFamily: 'Core-Text',
    fontSize: 14,
    fontWeight: 600,
  },
  body1: {
    fontFamily: 'Core-Text',
    fontSize: 16,
    fontWeightBold: 700,
  },
  body2: {
    fontFamily: 'Core-Text',
    fontSize: 14,
  },
  button: {
    fontFamily: 'Core-Text',
    fontSize: 15,
  },
  caption: {
    fontFamily: 'Core-Text',
    fontSize: 12,
  },
  captionBold: {
    fontFamily: 'Core-Text',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '18px',
  },
  overline: {
    fontFamily: 'Core-Text',
    fontSize: 12,
  },
  tooltip: {
    fontFamily: 'Core-Text',
    fontSize: 12,
    lineHeight: '16px',
  },
};

/**
 * See https://next.material-ui.com/customization/default-theme for MUI's default values
 */
export const createDefaultTheme = (options?: ThemeOptions): ThemeOptions => {
  const intermediateTheme = createTheme(options);
  const defaultOptions: ThemeOptions = {
    components,
    palette,
    typography,
    sfAccount: {
      styles: {
        warningIcon: {
          fontSize: 'medium',
          color: 'error.main',
          pr: 0.5,
        },
      },
      typographyVariants: {
        balance: 'subtitle1',
        portfolioName: 'subtitle2',
      },
    },
    sfAccountList: {
      styles: {},
      typographyVariants: {
        heading: 'h3',
      },
    },
    sfAccountInfo: {
      styles: {
        accountProgramLink: {
          color: 'text.primary',
        },
        subHeading: {
          color: 'text.secondary',
        },
      },
      typographyVariants: {
        account: 'caption',
      },
    },
    sfAccountTotal: {
      styles: {},
      typographyVariants: {
        heading: 'h3',
        balance: 'h4',
      },
    },
    sfAccountDetails: {
      root: {},
      styles: {
        dropdownLabel: {
          textTransform: 'none',
        },
        accountDropdown: { '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' } },
      },
      typographyVariants: {
        headerTitle: 'h3',
        headerLabel: 'body2',
        headerData: 'h5',
        tabTitle: 'button',
      },
    },
    sfAccountPerformance: {
      root: {},
      styles: {
        container: {
          my: 5,
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 1,
          background: '#fff',
          p: 6,
        },
        content: {
          background: '#FAFAFB',
        },
        maxDropdownHeight: '21em',
      },
      typographyVariants: {
        accountOverview: 'h3',
        date: 'caption',
        sectionHeading: 'h3',
        subHeadingValue: 'h1',
        subHeading: 'subtitle2',
        accountNumber: 'body2',
        viewPerformance: 'h5',
        accountBalance: 'caption',
        accountBalanceHeading: 'h3',
        balanceValue: 'h3',
        otherBalance: 'h6',
        selectedPeriod: 'caption',
        marketReviewTitle: 'h3',
        marketReviewSubtitle: 'caption',
        returnsHeading: 'h3',
      },
    },
    sfAccountProfile: {
      styles: {
        container: {
          mt: 9,
          mb: 8,
          px: 12,
          py: 9,
          border: '1px solid #E5E5E5',
          backgroundColor: 'white',
        },
        contactElements: {
          width: 0.32,
          my: 2,
          mr: 2,
        },
      },
    },
    sfAddFunds: {
      styles: {
        ctas: {
          changeInvestment: {
            width: 'fit-content',
            mt: 1,
          },
          editBrokerage: {
            width: 'fit-content',
            mb: 2,
          },
        },
        table: {
          '& .MuiTableRow-root': {
            '& .MuiTableCell-root:first-of-type': {
              width: 'auto',
            },
            '& .MuiTableCell-root:nth-of-type(2)': {
              [intermediateTheme.breakpoints.up('md')]: {
                width: '10%',
                minWidth: '140px',
              },
            },
            '& .MuiTableCell-root:last-of-type': {
              minWidth: '110px',
              [intermediateTheme.breakpoints.up('md')]: {
                width: '30%',
                minWidth: '300px',
              },
            },
          },
        },
      },
      typographyVariants: {
        sectionHeading: 'h3',
      },
    },
    sfAnnualReview: {
      styles: {
        warningIconColor: 'warning.light',
      },
    },
    sfAnnualReviewModal: {
      styles: {
        checkCircleIconColor: 'secondary.main',
      },
    },
    sfAssetAllocation: {
      styles: {},
      typographyVariants: {
        date: 'caption',
        sectionHeading: 'h3',
        sectionPortfolioName: 'h5',
        stockBondSplitLabel: 'h4',
      },
    },
    sfAssetAllocationTable: {
      styles: {
        percentBarHeight: 15,
        percentBarWidth: 60,
      },
      root: {
        '&>thead>tr>:first-of-type, &>thead>tr>:last-of-type, &>thead>tr>td, &>thead>tr>th, &>tbody>tr>:first-of-type, &>tbody>tr>:last-of-type, &>tbody>tr>td, &>tbody>tr>th': {
          p: {
            xs: 1,
            md: 2,
          },
        },
      },
    },
    sfAccountTypeDetailsModal: {
      styles: {
        a: {
          color: 'primary.main',
          textDecoration: 'none',
        },
      },
    },
    sfAutocomplete: {
      styles: {
        borderRadius: 1,
      },
    },
    sfBasicTable: {
      root: {
        '&': {
          p: 4,
          pt: 0,
          backgroundColor: '#FFFFFF',
          borderCollapse: 'separate',
        },
        '.MuiTableCell-head': {
          fontWeight: 600,
        },
      },
      styles: {},
    },
    sfNullState: {
      styles: {
        backgroundColor: '#FFFFFF',
        pt: 11,
        pb: 33,
        justifyContent: 'center',
      },
    },
    sfBenefits: {
      styles: {
        heading: 'primary.main',
      },
      typographyVariants: {
        heading: 'h3',
        subheading: 'subtitle1',
      },
    },
    sfChecklist: {
      styles: {
        title: { color: 'text.primary' },
        item: { color: 'text.primary' },
      },
      typographyVariants: {
        item: 'body1',
        title: 'subtitle1',
      },
    },
    sfComparison: {
      styles: {},
      typographyVariants: {
        heading: 'h3',
        stockBondSplitLabel: 'body1',
        stockBondSplitSubTextLabel: 'caption',
      },
    },
    sfClientCodeValidation: {
      styles: {
        error: {
          borderColor: palette.error.main,
        },
        focus: {
          outline: 'initial',
        },
        input: {
          borderRadius: '8px',
          border: '1px solid #757575',
          height: '48px',
          width: '36px',
          marginLeft: '8px',
          marginRight: '8px',
        },
      },
    },
    sfClientInfo: {
      styles: {
        header: {
          color: 'primary.main',
          mb: 2,
        },
        text: {
          color: 'text.primary',
        },
      },
      typographyVariants: {
        header: 'h4',
        email: 'body1',
        phone: 'body1',
        text: 'body1',
      },
    },
    sfClientPortfolioSearch: {
      styles: {
        table: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'secondary.main',
          borderRadius: '4px',
        },
        tableCell: {
          borderStyle: 'none',
        },
        tableRow: {
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'secondary.main',
          borderBottom: 'none',
        },
      },
    },
    sfCloseAccountModal: {
      styles: {
        closeAccountSelectedButton: {
          backgroundColor: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))`,
          border: `1px solid ${palette.primary.main}`,
        },
        inputLabel: {
          transform: 'none',
          position: 'relative',
          whiteSpace: 'normal',
          fontSize: 16,
          mb: 2,
        },
        summaryTitle: {
          textAlign: 'center',
        },
      },
    },
    sfComment: {
      styles: {
        defaultColor: '#6F777999',
      },
    },
    sfDashboard: {
      root: {
        '.MuiTabPanel-root': { py: 3, px: 0 },
      },
      styles: {
        mainSection: { backgroundColor: 'background.default' },
        tab: { textTransform: 'none' },
      },
    },
    sfDetailedChecklist: {
      styles: {
        title: {
          color: 'text.primary',
          variant: 'subtitle1',
        },
        subtitle: {
          color: 'text.primary',
          variant: 'subtitle1',
        },
        item: {
          variant: 'subtitle1',
          color: 'text.primary',
        },
        itemIcon: {
          border: '2px solid',
          borderColor: 'other.stroke',
          borderRadius: '100%',
          height: '32px',
          width: '32px',
        },
      },
    },
    sfDrawer: {
      styles: {
        actions: { px: 3, py: 2 },
        description: { px: 3, py: 2 },
        header: { px: 3, py: 2 },
        selectedItemColor: 'primary.main',
      },
    },
    sfDropdown: {
      styles: {
        iconColor: 'text.primary',
        madlib: {
          root: {
            '.MuiInput-underline:before': {
              borderBottomStyle: 'dashed',
            },
            '.MuiInput-root': { minHeight: 0 },
          },
          inputProps: { textTransform: 'lowercase' },
          dropdownProps: {
            paddingBottom: '5px',
          },
          selectedItemColor: 'primary.main',
        },
        menuItemPlaceholderColor: 'text.disabled',
        outlinedClassic: {
          inputLabel: {
            position: 'relative',
            transform: 'none',
            whiteSpace: 'normal',
          },
        },
      },
      typographyVariants: {
        accountDetailsInput: 'subtitle1',
      },
    },
    sfDisclosure: {
      styles: {},
      typographyVariants: {
        disclosureTable: 'body2',
        nav: 'body2',
      },
      root: {
        // the RteContent may contain unstyled link from contentstack
        a: {
          color: 'primary.main',
          textDecoration: 'none',
        },
      },
    },
    sfCreateFlagModal: {
      styles: {
        cancelButton: {
          borderRadius: '6px',
          color: 'primary.main',
        },
        escalateButton: {
          borderRadius: '6px',
        },
      },
    },
    sfFunding: {
      styles: {
        headingColor: 'primary.main',
        maxDropdownHeight: '21em',
        modals: {
          saveFundingDetails: {
            image: {
              mb: 1,
              mt: 2,
              maxHeight: '20px',
            },
          },
        },
        warningMessage: {
          button: { p: 1, color: '#000000' },
          container: {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
            my: 2,
            p: 2,
            width: '100%',
          },
          icon: {
            color: 'info.main',
          },
          heading: {
            color: 'text.primary',
          },
        },
      },
      typographyVariants: {
        heading: 'h3',
        radioCardDescription: 'body2',
        sectionHeading: 'subtitle1',
        dropdownLabel: 'caption',
        link: 'body2',
      },
    },
    sfGreeting: {
      styles: {
        emailIcon: {
          paddingRight: 1,
        },
        displayName: {
          paddingBottom: 4,
        },
        headingColor: 'primary.main',
      },
      typographyVariants: {},
    },
    sfGoals: {
      styles: {
        associatedAccounts: {
          radioCard: {
            width: 300,
          },
        },
        axisLineColor: palette.other.stroke,
        margin: {
          top: 30,
          bottom: 0,
          right: 5,
          left: 5,
        },
        nullState: {
          minHeight: 439,
          background: 'linear-gradient(0deg, #F5F5F5 0%, #FAFAFA 100%)',
        },
        savingsView: {
          strokeWidth: 1,
          goalDot: {
            fill: palette.secondary.main,
            r: 10,
          },
        },
        targetDot: {
          fill: '#fff',
          radius: 8,
          strokeWidth: 8,
        },
        titleLink: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        },
      },
    },
    sfHowItWorks: {
      styles: {
        heading: 'primary.main',
        videoTitle: 'text.secondary',
        itemIconColor: 'primary.main',
        numberColor: '#fff',
      },
      typographyVariants: {
        heading: 'h3',
      },
    },
    sfInvestmentPortfolio: {
      styles: {
        slider: {
          '& input[type="range"]': {
            WebkitAppearance: 'slider-vertical',
          },
          '& .MuiSlider-rail, &.MuiSlider-root': {
            color: 'info.main',
          },
          '& .MuiSlider-thumb': {
            borderRadius: '10px',
            backgroundColor: 'white',
            border: '1px solid gray',
            width: '20px',
            height: '20px',
          },
          '& .MuiSlider-thumb:hover, .MuiSlider-thumb.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${alpha(palette.info.main, 0.16)}`,
          },
          '& .MuiSlider-thumb.Mui-active': { boxShadow: `0px 0px 0px 14px ${alpha(palette.info.main, 0.16)}` },
          '& .MuiSlider-markLabel, & .MuiSlider-markLabelActive': {
            color: 'text.primary',
            fontSize: '14px',
            fontWeight: 400,
            ml: 4,
          },
          '& .MuiSlider-markLabelActive': {
            fontWeight: 700,
          },
          height: 300,
        },
      },
    },
    sfInvestmentRestrictions: {
      styles: {
        tablePadding: '16px !important',
      },
    },
    sfModelPortfolio: {
      styles: {
        heading: 'text.primary',
        cardheading: 'primary.main',
        buttonColor: 'primary.main',
        arrowHeadColor: '#fff',
      },
      typographyVariants: {
        heading: 'h3',
        cardheading: 'h4',
        centerText: 'h5',
        centerSubText: 'h5',
      },
    },
    sfOpsDashboard: {
      styles: {
        actionIcons: {
          height: '20px',
        },
        nullStateContent: {
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
          width: '100%',
          height: '250px',
          '& p': {
            fontSize: '16px',
            fontWeight: '600',
            color: 'text.primary',
          },
        },
        opsHeaderLogo: {
          height: '17px',
          width: '90px',
        },
        opsHeader: {
          borderRadius: '0',
          color: 'text.primary',
          minWidth: '165px',
          py: 4,
        },
        opsHeaderSubBorder: {
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'secondary.main',
        },
        tabsContainer: {
          background: 'linear-gradient(90deg, #363F50 0%, #363F50 76.3%, #3A60F8 100%)',
          pt: 0,
          px: 9.5,
        },
        header: {
          color: '#FFFFFF',
        },
        tab: {
          textTransform: 'none',
          color: '#91969F',
          '&.Mui-selected': {
            backgroundColor: palette.primary.selected,
            color: palette.primary.main,
          },
        },
        partitionTabs: {
          textTransform: 'none',
          '&.Mui-selected': {
            color: palette.other.backgroundBlue,
          },
        },
        tabsIndicatorColor: palette.other.backgroundBlue,
        tabScrollButtons: {
          color: '#91969F',
        },
        chip: {
          label: {
            cancelled: { backgroundColor: 'error.main', color: 'error.contrastText' },
            completed: { backgroundColor: 'success.main', color: 'text.primary' },
            approved: { backgroundColor: 'success.main', color: 'text.primary' },
            default: { backgroundColor: 'other.divider', color: 'text.primary' },
            active: { backgroundColor: 'warning.main', color: 'warning.textDark' },
            declined: { backgroundColor: 'error.dark', color: 'error.textDark' },
            rejected: { backgroundColor: 'error.dark', color: 'error.textDark' },
            succeeded: { backgroundColor: 'success.dark', color: 'success.contrastText' },
            failed: { backgroundColor: 'error.dark', color: 'error.contrastText' },
          },
          root: {
            p: 2,
            minWidth: '105px',
          },
        },
      },
    },
    sfPendingStatus: {
      styles: {
        noDescriptionStatusBars: {
          flexGrow: '1 !important',
          maxWidth: '25% !important',
        },
      },
    },
    sfFaDashboard: {
      styles: {
        statusFilterColumn: {
          display: 'flex',
          alignItems: 'center',
        },
        table: {
          border: '1px solid #6F77794D',
          borderRadius: '4px',
        },
      },
      typographyVariants: {
        applicationInProgressTableData: 'body2',
        activeClientsTableData: 'body2',
        accountActionsTableData: 'body2',
      },
    },
    sfFileUploader: {
      styles: {
        card: {
          p: 1,
          display: 'flex',
          flexDirection: 'row',
          my: 1,
          background: 'linear-gradient(0deg, rgba(111, 119, 121, 0.05), rgba(111, 119, 121, 0.05)), #FFFFFF',
          borderRadius: '4px',
          border: '1px solid',
          flexWrap: 'wrap',
          borderColor: 'secondary.light',
          color: 'text.primary',
        },
        fileName: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: '250px',
          whiteSpace: 'nowrap',
        },
      },
    },
    sfPerformanceChart: {
      styles: {
        linkDecoration: 'underline',
        lineColor: palette.primary.main,
        mobileHeader: {
          borderBottom: 1,
          borderColor: 'other.divider',
          pb: 2,
          mb: 2,
        },
        displayDialog: {
          maxWidth: 300,
          p: 3,
        },
      },
    },
    sfProjections: {
      styles: {
        heading: 'text.primary',
      },
      typographyVariants: {
        heading: 'h3',
      },
    },
    sfProjectionChart: {
      styles: {
        outer: 'rgba(35, 101, 246, 0.3)',
        inner: 'rgba(35, 101, 246, 0.5)',
        median: 'rgba(35, 101, 246, 1)',
        strongMarket: 'rgba(35, 101, 246, 1)',
        averageMarket: 'rgba(35, 101, 246, 0.5)',
        weakMarket: 'rgba(35, 101, 246, 0.3)',
        xAxisStroke: '#DADADA',
        cardHeaderBackground: 'text.secondary',
        cardHeaderColor: '#fff',
      },
    },
    sfPlaybackeDisclosure: {
      styles: {
        expandIcon: {
          background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF',
          borderRadius: '50%',
          color: palette.other.expandIcon,
        },
      },
      typographyVariants: {
        header: 'body2',
      },
    },
    sfQuestionnaire: {
      styles: {
        card: {
          background: 'linear-gradient(0deg, rgba(35, 101, 246, 0.08), rgba(35, 101, 246, 0.08)), #FFFFFF',
          borderColor: 'rgba(35, 101, 246, 0.5)',
          minHeight: 300,
        },
        heading: {
          color: 'primary.main',
          textAlign: 'center',
        },
        input: {
          color: '#000000',
        },
        maxDropdownHeight: '550px',
        maxWidth: 900,
      },
      typographyVariants: {
        heading: 'h3',
        editButton: 'h6',
      },
    },
    sfRadioGroup: {
      styles: {
        base: {
          alignItems: 'flex-start',
        },
        radioBorder: {
          base: {
            border: '3px solid',
            borderColor: 'other.divider',
            borderRadius: 2,
            mx: 0,
            my: 1,
            p: 2,
          },
          selectedColor: 'primary.main',
        },
      },
    },
    sfReturnsChart: {
      styles: {
        colors: {
          default: palette.primary.main,
          BBG000BZZS63: '#4CAF50',
          BBG000TH7DF8: '#B71C1C',
        },
      },
    },
    sfRiskPreferenceHistoryModal: {
      styles: {
        cancelButton: {
          borderRadius: '0.4rem',
        },
      },
    },
    sfRiskSpectrum: {
      styles: {
        background: 'linear-gradient(90deg, #3C8AC2 0%, #4DAF6F 23.3%, #D47924 61.52%, #DE361F 100%)',
        disabledBackgroundColor: '#BFBFBF',
        borderLighter: 'rgba(0,0,0,0.12)',
        borderLight: 'rgba(0,0,0,0.23)',
        indicatorPrimaryRoot: {
          color: 'text.primary',
        },
        indicatorSecondaryRoot: {
          color: 'text.secondary',
        },
        label: {
          top: -40,
          height: 18,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        },
        slider: {
          height: 20,
          '&.MuiSlider-root': { m: 0, p: 0 },
          '&.MuiSlider-dragging .MuiButton-root:hover': { backgroundColor: 'inherit' },
          '& .MuiSlider-thumb': { width: 0 },
          '& .MuiSlider-mark, .MuiSlider-markLabel, .MuiSlider-track': { display: 'none' },
          '& :hover, .MuiSlider-thumb.Mui-active, .MuiSlider-thumb.Mui-focusVisible': { boxShadow: 'none' },
        },
      },
    },
    sfRiskSpectrumPdf: {
      styles: {
        riskSpectrumColors: ['#3C8AC2', '#47A190', '#C5802D', '#DB4F22', '#DE3620'],
      },
    },
    sfRiskToleranceResult: {
      styles: {
        targetAllocationImage: {
          fill: '#7849B8',
        },
        bandHeadingColor: 'text.primary',
        iconSize: {
          height: 118,
          width: 118,
          [intermediateTheme.breakpoints.down('md')]: {
            height: 80,
            width: 80,
          },
        },
      },
      typographyVariants: {
        bandHeading: 'h3',
      },
    },
    sfShoppingCart: {
      styles: {
        riskNoteContainer: {
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #f44336',
          borderRadius: '4px',
          width: '100%',
        },
        shoppingCartFormContainer: { border: '1px solid', borderColor: 'grey.300' },
        snackBar: {
          '& .MuiPaper-root': {
            padding: 0,
          },
          '& .MuiSnackbarContent-message': {
            padding: 0,
            width: '100%',
          },
          '& .MuiAlert-root': {
            padding: '8px',
          },
        },
        totalPercentageErrorLabelColor: '#FF0000',
        shoppingCartDropdown: {
          '.MenuItem-label': {
            overflow: 'hidden',
            width: 'calc(75%)',
            whiteSpace: 'nowrap',
            display: 'block',
            textOverflow: 'ellipsis',
          },
        },
      },
      typographyVariants: {
        stockBondSplitLabel: 'h4',
        stockBondSplitSubTextLabel: 'body1',
      },
    },
    sfStatusButton: {
      styles: {
        label: {
          cancelled: { backgroundColor: 'error.light', color: 'text.primary' },
          completed: { backgroundColor: 'success.main', color: 'success.textDark' },
          default: { backgroundColor: 'other.neutral', color: 'text.primary' },
          in_progress: { backgroundColor: 'info.light', color: 'text.primary' },
          pending: { backgroundColor: 'other.neutral', color: 'text.primary' },
          unrecognized: { backgroundColor: 'error.main', color: 'error.contrastText' },
          needs_review: { backgroundColor: 'error.light', color: 'text.primary' },
          funded: { backgroundColor: 'success.main', color: 'text.primary' },
          below_minimum: { backgroundColor: 'other.neutral', color: 'text.primary' },
        },
        root: {},
      },
    },
    sfTooltip: {
      styles: {
        content: {
          color: 'text.primary',
          p: 1,
        },
        iconButton: {
          alignItems: 'baseline',
          borderRadius: '50%',
          color: 'text.primary',
          fontSize: 16,
          mb: 0.5,
        },
      },
    },
    sfModal: {
      root: {},
      styles: {},
      typographyVariants: {
        title: 'body1',
      },
    },
    sfModalLink: {
      styles: {
        borderBottom: '1px dashed',
      },
      typographyVariants: {
        link: 'body2',
      },
    },
    sfSliderSelector: {
      styles: {
        backgroundColor: '#fff',
        border: '1px solid #c1beba',
        boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.2)',
        boxShadowCaret: '-3px 3px 3px 0 rgba(0, 0, 0, 0.2)',
        height: 46,
        width: 120,
      },
    },
    sfTextarea: {
      styles: {
        border: '1px solid #c1beba',
        borderRadius: 1,
        boxShadow: 'inset 0 2px 0 rgba(0, 0, 0, 0.05)',
        padding: '5px 10px',
        focusBoxShadow: textAreaFocusBoxShadow,
      },
    },
    sfKebabMenu: {
      styles: {
        button: {
          p: 1,
          minWidth: 'auto',
        },
        mobileMenuItem: {
          borderBottom: '1px solid',
          borderColor: 'grey.300',
          py: 3,
        },
      },
      typographyVariants: {
        listItem: 'body2',
      },
    },
    sfMadLibs: {
      styles: {
        disclosureColor: 'text.primary',
        headingColor: 'primary.main',
        secondaryInputColor: 'text.secondary',
      },
      typographyVariants: {
        heading: 'h3',
        input: 'h3',
        dropdown: 'h3',
        tooltip: 'body2',
      },
    },
    sfMadlibsIcon: {
      styles: {
        root: {
          mx: 'auto',
          height: { xs: 80, md: 100 },
          width: { xs: 80, md: 100 },
        },
        speechBubble: {
          left: {
            fill: 'info.light',
            textFill: 'info.dark',
          },
          right: {
            fill: 'info.dark',
            textFill: 'info.light',
          },
        },
      },
    },
    sfSupportContact: {
      styles: {
        heading: {
          color: 'text.primary',
          margin: '0',
        },
      },
      typographyVariants: {
        heading: 'h3',
      },
    },
    sfPlan: {
      styles: {
        headingColor: 'primary.main',
        portfolioCompareStatusChip: {
          label: {
            recommended: { backgroundColor: 'primary.selected', color: 'primary.dark' },
            required: { backgroundColor: 'warning.main', color: 'warning.textDark' },
            completed: { backgroundColor: 'success.light', color: 'success.dark' },
          },
        },
      },
      typographyVariants: {
        heading: 'h3',
      },
    },
    sfAssetConsolidation: {
      styles: {
        header: {
          background: '#FFFFFF',
          color: 'primary.main',
        },
        modals: {
          confirmFundingDetailsOverride: {
            image: {
              maxHeight: '20px',
              mb: 1,
              mt: 3,
            },
          },
        },
      },
      typographyVariants: {
        heading: 'h3',
      },
    },
    sfClientAuthSuccess: {
      styles: {
        productImageHeight: '24',
        successImageHeight: '231',
      },
    },
    sfPortfolioCompare: {
      styles: {
        linearProgress: {
          backgroundColor: palette.other.divider,
          height: '8px',
        },
        donut: {
          backgroundColor: palette.other.divider,
          color: palette.primary.main,
        },
      },
    },
    sfPortfolioDetails: {
      styles: {
        titleColor: 'text.secondary',
      },
      typographyVariants: {
        heading: 'h6',
        stockBondSplitLabel: 'h4',
        stockBondSplitSubTextLabel: 'body1',
        recommendedProductValue: 'body1',
      },
    },
    sfPaperwork: {
      root: {
        '.MuiInputLabel-root': {
          mb: 0,
          ml: 0,
        },
      },
      styles: {
        borderColor: 'secondary.light',
        headingColor: 'primary.main',
        maxDropdownHeight: '450px',
        sectionHeader: { borderTop: 1, borderBottom: 1, borderColor: 'primary.main' },
        sectionHeaderNumber: {
          borderColor: 'primary.main',
          color: 'primary.main',
          border: '1px solid',
          borderRadius: 4,
          px: 1,
          py: 0.25,
        },
        contactElements: {
          display: 'flex',
          my: 2,
          flex: '1 0',
          flexWrap: 'wrap',
        },
      },
      typographyVariants: {
        heading: 'h3',
      },
    },
    sfPricing: {
      styles: {
        title: { variant: 'h3', color: 'text.primary' },
        sliderColor: 'primary.main',
      },
    },
    sfDocusign: {
      styles: {
        headingColor: 'primary.main',
      },
      typographyVariants: {
        heading: 'h4',
      },
    },
    sfDownloadProposal: {
      styles: {
        downloadButton: {
          textDecoration: 'underline',
        },
      },
    },
    sfPlayback: {
      styles: {
        inlineButton: {
          padding: '2px 0 8px 0',
        },
        pricingSummary: {
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
          padding: '20px',
        },
      },
      typographyVariants: {
        heading: 'h3',
        sectionHeading: 'h5',
        stockBondSplitSubTextLabel: 'caption',
      },
    },
    sfPricingSummary: {
      styles: {
        accountValue: {
          display: 'block',
          justifyContent: 'space-between',
        },
        accountValueInput: {
          padding: 1,
        },
        discountCell: {
          color: 'primary.main',
        },
        feeLabelCell: {
          alignItems: 'center',
          borderBottom: 'none',
          paddingY: 1,
        },
        feeValueCell: {
          borderBottom: 'none',
          paddingY: 1,
          textAlign: 'right',
        },
      },
      typographyVariants: {
        accountValue: 'body2',
        accountValueLabel: 'body2',
        advancedPricingLink: 'body2',
        estimatedPeriodCost: 'body2',
        estimatedTotalCost: 'subtitle1',
        feeItem: 'body2',
        tiersLink: 'caption',
      },
    },
    sfPricingSummaryButton: {
      styles: {
        backgroundColor: '#323232',
        position: 'fixed',
        bottom: 10,
        right: 16,
      },
    },
    sfPricingSummaryModal: {
      styles: {
        headerCta: {
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          minWidth: 0,
          padding: 1,
        },
      },
    },
    sfProgramFeeCalculator: {
      styles: {
        body: { color: 'text.secondary', display: 'inline-flex', alignItems: 'center' },
        container: { pt: 5, pb: 3 },
        input: { color: 'primary.main', width: '100px', padding: 0, textAlign: 'center' },
        title: { color: 'text.primary' },
      },
      typographyVariants: {
        title: 'subtitle1',
      },
    },
    sfOnboardingCompleted: {
      styles: {
        headingColor: 'primary.main',
      },
      typographyVariants: {
        heading: 'h3',
        subHeading: 'h3',
        subtitle2: 'body2',
      },
    },
    sfOnboardingPageCtas: {
      styles: {
        container: {},
        ctaContainer: {},
        link: {
          margin: 1,
        },
      },
    },
    sfOnboardingPageHeader: {
      styles: {
        container: { my: 2, textAlign: 'center', width: '100%' },
        icon: { my: 2, width: 100 },
        title: { color: 'primary.main', my: 2 },
      },
      typographyVariants: { title: 'h3' },
    },
    sfWaysToGetAhead: {
      root: {
        '&': { py: 3 },
      },
      styles: {
        carousel: { cta: { border: '1px solid' } },
      },
      typographyVariants: { containerHeading: 'h4', cardHeading: 'subtitle2' },
    },
    sfMarketing: {
      styles: {
        background: {
          secondary: alpha(palette.primary.main, 0.08),
          primary: '#fff',
        },
      },
    },
    sfMarketingIntro: {
      styles: {
        background: 'linear-gradient(90deg, #F9FAF9 22.4%, rgba(255, 255, 255, 0) 71.55%)',
      },
      typographyVariants: {
        heading: 'h1',
        body: 'h3',
      },
    },
    sfRce: {
      styles: {
        card: {
          p: 4,
          [intermediateTheme.breakpoints.down('md')]: {
            p: 2,
          },
        },
      },
    },
    sfFATabActiveClient: {
      styles: {
        warningIconColor: 'warning.light',
      },
    },
    sfErrorComponent: {
      styles: {},
    },
    sfFAQs: {
      styles: {},
      typographyVariants: {
        heading: 'h3',
      },
    },
    sfDocumentUploader: {
      styles: {
        card: {
          height: '250px',
          margin: '30px 0px',
          border: '1px dashed',
          borderColor: 'grey.300',
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: 'rgba(35, 101, 246, 0.08)',
            border: '1px dashed #2365F6',
          },
        },
        iconCard: {
          fontSize: 28,
          color: 'primary.main',
          padding: '24px',
          borderRadius: '4px',
          backgroundColor: 'rgba(35, 101, 246, 0.08)',
          border: 'none',
          '&:hover': {
            backgroundColor: 'rgba(35, 101, 246, 0.08)',
            border: 'none',
          },
        },
        innerCard: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'justify-content',
          padding: '32px 32px',
        },
        passwordCard: {
          alignItems: 'flex-start',
        },
      },
    },
    sfFilterContainer: {
      styles: {
        root: {
          border: 1,
          borderColor: 'other.divider',
          paddingLeft: '2vw',
        },
      },
    },
    sfPortfolioSelection: {
      styles: {
        filterContainer: {
          overflowY: 'auto',
          maxHeight: '100vh',
          position: 'sticky',
          alignSelf: 'start',
          top: 0,
        },
        headerStrategyContainer: {
          borderRadius: 0.5,
          pb: 2,
          pt: 1,
          px: 3,
          my: 3,
        },
        highlightColor: 'primary.main',
        portfolioSelectionCard: {
          root: {},
          table: {
            cell: {
              whiteSpace: 'nowrap',
              width: '100%',
            },
            cta: {
              py: 0.5,
              px: 1.25,
            },
            link: { textDecorationStyle: 'dashed', textUnderlineOffset: 4 },
            root: {
              '&>thead>tr>:first-of-type, &>thead>tr>:last-of-type, &>thead>tr>td, &>thead>tr>th, &>tbody>tr>:first-of-type, &>tbody>tr>:last-of-type, &>tbody>tr>td, &>tbody>tr>th': {
                p: {
                  xs: 1,
                  md: 2,
                },
              },
            },
          },
        },
      },
      typographyVariants: {
        editRiskToleranceLink: 'body2',
        nullState: 'body2',
        riskSpectrumLabel: 'body1',
        stockBondSplitLabel: 'h4',
        title: 'h3',
      },
    },
    sfPortfolioSelectionSimple: {
      styles: {
        headerItems: {
          width: 384,
        },
        portfolioTile: {
          cta: {
            py: 1,
            px: 2.5,
          },
          description: {
            height: 120,
            overflowY: 'scroll',
            my: 2,
          },
          link: {
            textDecorationStyle: 'dashed',
            textUnderlineOffset: 4,
            display: 'block',
          },
          tagChip: {
            backgroundColor: 'other.divider',
            mt: -2,
          },
          tile: {
            border: '1px solid rgba(0,0,0,0.12)',
            borderRadius: '4px',
            px: 4,
          },
          tiles: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            mt: 9,
            mb: 5,
            gridColumnGap: 94,
          },
        },
      },
      typographyVariants: {
        aboutPortfolio: 'subtitle2',
        portfolioDescription: 'body1',
        portfolioName: 'body1',
        stockBondSplitLabel: 'h4',
      },
    },
    sfPricingModal: {
      styles: {},
      typographyVariants: { feeItem: 'subtitle1' },
    },
    sfTaxWithholdingPlayback: {
      root: {
        '.MuiGrid-root': {
          'div:empty': {
            display: 'none',
          },
        },
      },
      styles: {},
    },
    sfTlhModal: {
      styles: {
        body: {
          mt: 1,
          ul: {
            m: 0,
            pl: 2,
          },
          li: { pb: 2 },
        },
        disclosure: {
          color: 'text.secondary',
          display: 'block',
          mt: 4,
        },
        links: {
          // the RteContent may contain unstyled link from contentstack
          a: {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
      typographyVariants: {
        modalHeading: 'h5',
        modalDisclosure: 'caption',
        lastPageHeading: 'h4',
      },
    },
    sfWidgets: {
      styles: {
        partnerHeading: {
          backgroundColor: 'secondary.dark',
          color: 'secondary.contrastText',
        },
        sectionHeading: {
          backgroundColor: 'secondary.light',
          color: 'secondary.contrastText',
        },
        button: {
          borderRadius: 1,
        },
        card: {
          width: 719,
          px: 3,
          py: 5,
        },
      },
    },
    sfPoweredByFooter: {
      styles: {
        footerBackgroundColor: '#F2F2F5',
        partnerLogo: {
          height: '18px',
          width: '99px',
          marginBottom: '0.2rem',
        },
        sigFigContainer: {
          justifyContent: 'center',
          alignItems: 'center',
          borderTop: '1px solid #E6E8ED',
          paddingTop: '0.2rem',
        },
        container: {
          padding: '44px 0',
          flexDirection: 'column',
          alignItems: 'flex-start',
          maxWidth: 'max-content',
        },
        poweredByWording: {
          marginRight: '0.3rem',
          marginBottom: '0.1rem',
          fontSize: '0.75rem',
        },
      },
    },
    sfResolveFlagModal: {
      root: {},
      styles: {
        reasonCard: {
          backgroundColor: '#F2F2F5',
          minWidth: '155px',
          py: 2,
          px: 3,
          my: 0.25,
          boxShadow: 'none',
        },
        reasonDate: {
          fontSize: 'small',
        },
        reasonIcon: { marginLeft: '10px' },
        reasonTitle: {
          fontWeight: 'bold',
          my: 1,
        },
        resolveButton: {},
        resolvedIcon: {
          mx: 2,
        },
      },
    },
    sfApproveModelChangeModal: {
      styles: {
        icon: {
          width: '1.2rem',
        },
      },
    },
    sfConfirmAccountApplicationModal: {
      styles: {
        icon: {
          width: 22,
          height: 19,
          marginBottom: 20,
          marginTop: 8,
        },
      },
    },
    sfStatusChangeModal: {
      root: {
        '.MuiDialogContent-root': {
          border: 0,
        },
      },
      styles: {
        confirmationLogo: {
          padding: '40px 0px',
        },
      },
    },
    sfSuspension: {
      styles: {
        suspensionTagStyleMap: {
          bord: '#FFC8CC',
          custodial: '#FFE1BD',
          ops: '#C7E5FD',
        },
      },
    },
    sfVolatilityBar: {
      styles: {
        currentGuideLabel: {
          alignItems: 'center',
          textAlign: 'center',
          position: 'absolute',
        },
        guideLabel: {
          alignItems: 'center',
          textAlign: 'center',
        },
        imageContainer: {
          height: '18px',
        },
        upArrow: {
          fontSize: 32,
        },
        volatilityBand: {
          background: `linear-gradient(
            90deg,
            #198896 -270.26%,
            #678a5a -152.53%,
            #8f8c42 -24.56%,
            #e98823 90.61%,
            #fc6721 192.99%
          )`,
          opacity: '0.85',
          border: '1px solid #757575',
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
          borderRadius: '8px',
          height: '13px',
          position: 'absolute',
        },
        volatilityBar: {
          background: `linear-gradient(
            90deg,
            rgba(25, 136, 150, 0.39) 0%,
            rgba(103, 138, 90, 0.39) 25.22%,
            rgba(143, 140, 66, 0.39) 52.62%,
            rgba(233, 136, 35, 0.39) 77.29%,
            rgba(252, 103, 33, 0.39) 99.22%
          )`,
          borderRadius: '8px',
          overFlow: 'hidden',
          height: '15px',
          justifyItems: 'center',
        },
        volatilityBarContainer: {
          width: '100%',
          position: 'relative',
        },
      },
    },
    sfTransferTable: {
      styles: {},
    },
    sfChip: {
      styles: {
        statusChip: { borderRadius: 2, fontWeight: 700 },
        tooltipButton: {
          background: 'transparent',
          padding: 0,
        },
      },
    },
    sfWithdrawalModal: {
      styles: {
        summaryTitle: {
          textAlign: 'center',
        },
        taxWithholdingDropdown: {
          '.MenuItem-label': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
            width: '100%',
          },
          '.MuiGrid-root .MuiGrid-item': {
            maxWidth: '100%',
          },
        },
      },
    },
    sfAccountDetailsHoldings: {
      styles: {
        table: {
          borderCollapse: 'separate',
          borderSpacing: '0px 12px',
          '& .MuiTableRow-root': {
            '& .MuiOutlinedInput-root': {
              padding: '0px',
            },
            '& .MuiTableCell-root': {
              padding: '0px 6px',
              '& .MuiNativeSelect-select': {
                padding: '12px',
              },
              '& .MuiInputBase-input': {
                padding: '12px',
              },
            },
            '& .MuiTableCell-root:first-of-type': {
              paddingLeft: '0px',
            },
            '& .MuiTableCell-root:last-of-type': {
              paddingRight: '0px',
            },
          },
          '& .MuiTableRow-head': {
            '& .MuiTableCell-head': {
              padding: '16px 0px',
            },
          },
        },
        drawer: {
          '& .MuiPaper-root': {
            top: '0',
            margin: 'auto 16px',
            '& .MuiBox-root': {
              paddingTop: '40px 24px',
            },
          },
        },
      },
    },
  };

  return options ? merge({}, defaultOptions, options) : defaultOptions;
};

export const defaultTheme = createDefaultTheme();

export * from './types';

export const getTextProps = (
  {
    palette: {
      text: { primary },
    },
    typography: typographyStyles,
  }: Theme,
  variant: SfVariant,
): SVGProps<SVGTextElement> => {
  const { fontFamily, fontWeight, fontSize, letterSpacing } = typographyStyles[variant];
  return { fontFamily, fontWeight, fontSize, letterSpacing, fill: primary };
};

/**
 * The sx prop may be either an array or an object.
 * This helper method converts it to an array for spreading in the component.
 * https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
 */
export const getSxPropsArray = (sxProps?: SxProps<Theme>) => {
  const sxValue = sxProps ?? [];
  return Array.isArray(sxValue) ? sxValue : [sxValue];
};
