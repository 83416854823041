import { QueryHookOptions, QueryResult } from '@apollo/client';

import {
  GetRecommendedProduct,
  GetRecommendedProduct_managedProduct_calculatedRecommendations,
  GetRecommendedProductVariables,
} from './__generated__/GetRecommendedProduct';
import { GetRelatedPartyIds, GetRelatedPartyIdsVariables } from './__generated__/GetRelatedPartyIds';
import * as queries from './query.gql';

import { useSymphonyQuery } from '~/utils/symphony';

export type CalculatedRecommendations = GetRecommendedProduct_managedProduct_calculatedRecommendations;

export const useGetRecommendedProduct = (
  options?: QueryHookOptions<GetRecommendedProduct, GetRecommendedProductVariables>,
): QueryResult<GetRecommendedProduct, GetRecommendedProductVariables> => {
  return useSymphonyQuery(queries.GetRecommendedProduct, options);
};

export const useGetRelatedPartyIds = (
  options?: QueryHookOptions<GetRelatedPartyIds, GetRelatedPartyIdsVariables>,
): QueryResult<GetRelatedPartyIds, GetRelatedPartyIdsVariables> => {
  return useSymphonyQuery(queries.GetRelatedPartyIds, options);
};
